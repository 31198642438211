.vh80 {
  height: 85vh;
}
.moveRight {
  right: 0%;
  animation: moveRight 1s ease-in;
}
.loginBg {
  background: linear-gradient(135deg, #198754, #F8F9FA);
}
@keyframes moveRight {
  0% {
    right: 50%;
  }
  100% {
    right: 0%;
  }
}
.authSlidePic {
  position: absolute;
}
.authSlidePic img {
  height: 85vh;
}
.provileAvtar img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 0.5px dashed gray;
}
label {
  margin-top: 20px;
}
.mobNavSlide {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  z-index: 2;
  animation: moveRight 0.5s ease-in;
}
@keyframes moveRight {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}
.storyImage {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 3px solid #198754;
  padding: 2px;
}
.marginTopSlider {
  margin-top: -30px;
}
.width50-100 {
  width: 50%;
}
.cardHover:hover{
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.175) !important;
}
/*  */
@media (max-width: 500px) {
  .storyImage {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 2px solid #198754;
    padding: 1px;
  }
  .width50-100 {
    width: 100%;
  }
  .marginTopSlider {
    margin-top: -70px;
  }
  .fontSize12 {
    font-size: 12px !important;
  }
  
}
@media (max-width: 800px) {
  .loginBg{
    background: #198754;
  }
}
.fontMono {
  font-family: monospace;
}


